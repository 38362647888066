import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 12px 24px;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 1.5em;
  color: var(--secondary-text);
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.7);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  margin-top: 24px;
  @media (min-width: 767px) {
    width: 600px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const CTA = styled.img`
  margin: 10vh;
  max-width: 700px;
`;

export const Roadmap = styled.img`
  margin: 10vh;
  margin-bottom: 0;
  max-width: 950px;
`;

export const Socials = styled.img`
  max-width: 35px;
  margin: 5px;
  opacity: 30%;
`;

export const RoadmapMax = styled.img`
  max-width: 100%;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MINTABLE: false,
    ROADMAP: false,
    TOKEN_ADDRESS: " ",
  });

  const url =
    "https://raw.githubusercontent.com/littleturtleclub/ca_token/refs/heads/main/token.json";
  const cacheBustingUrl = `${url}?t=${new Date().getTime()}`;

  // Fetch the JSON file
  fetch(cacheBustingUrl, { cache: "no-store" })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // Parse the JSON response
    })
    .then((data) => {
      // Assuming the JSON structure has a "token" property
      CONFIG.TOKEN_ADDRESS = data.token;
      console.log("Tokens:", data.token);
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
  const CATOKEN = CONFIG.TOKEN_ADDRESS;

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const CopyInputBox = () => {
    const [value, setValue] = useState(CATOKEN);

    const handleCopy = () => {
      navigator.clipboard.writeText(value).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    };

    return (
      <input
        type="text"
        value={value}
        readOnly
        onClick={handleCopy}
        style={{
          padding: "10px",
          backgroundColor: "var(--primary)",
          borderRadius: "5px",
          border: "1px solid #ccc",
          width: "370px",
          textAlign: "center",
          cursor: "pointer",
        }}
      />
    );
  };

  const getConfig = async () => {
    const configResponse = await fetch("./config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const image2 = document.querySelector(".scrolling-image-left");
      const image = document.querySelector(".scrolling-image");

      if (image) {
        image.style.left = `${-scrollPosition * 0.2}px`; // Adjust the multiplier as needed
      }

      if (image2) {
        image2.style.left = `${-scrollPosition * 0.65 + 1400}px`; // Adjust the multiplier as needed
        // image2.style.right = `${-scrollPosition * 0.4 - 500}px`; // Adjust the multiplier as needed
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "./config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"./config/images/logo.png"} />
        <div style={{ position: "fixed", right: "30px", paddingTop: "24px" }}>
          <a href="https://twitter.com/LittleTurtleSol" target="_blank">
            <Socials alt={"example"} src={"./config/images/twitter.png"} />
          </a>
          <a href={`https://pump.fun/coin/${CATOKEN}`} target="_blank">
            <Socials alt={"example"} src={"./config/images/discord.png"} />
          </a>
        </div>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} ai={"center"}>
            <CTA alt={"Little Turtles"} src={"./config/images/turtlex4.gif"} />
            <p style={{ textAlign: "center" }}>
              <s.TextDescription style={{ fontSize: "2em" }}>
                We like turtles.<br></br>
              </s.TextDescription>

              <CopyInputBox />
            </p>

            <s.Container
              style={{ padding: 24, maxWidth: "768px", fontSize: "2em" }}
            ></s.Container>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        {CONFIG.MINTABLE ? (
          <ResponsiveWrapper
            flex={1}
            ai={"center"}
            jc={"center"}
            style={{ padding: 24, maxWidth: "1200px" }}
          >
            <s.SpacerLarge />
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 24,
                borderRadius: 24,
                boxShadow: "0px 5px 11px 2px rgba(0, 0, 0, 0.7)",
              }}
            >
              <StyledImg
                alt={"Little Turtle Club"}
                src={"./config/images/example.gif"}
              />

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 50,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                {data.totalSupply > 0 ? data.totalSupply : "????"} /{" "}
                {CONFIG.MAX_SUPPLY}
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.TextDescription>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.Container>
          </ResponsiveWrapper>
        ) : (
          ""
        )}
        <s.SpacerMedium />
        <div className="angled-div">
          10% of all profits the dev makes goes to charity to saving the
          turtles.
        </div>
        <ResponsiveWrapper>
          <p
            style={{
              fontSize: "48px",
              maxWidth: "800px",
              margin: "auto",
              lineHeight: "72px",
            }}
          >
            Welcome to The Little Turtle Club, the memecoin that’s here to save
            turtles and vibe while doing it!
            <br></br>
            <br></br>
            🐢💸 We’re the first coin ever to turn memes into action, with 10%
            of the dev’s profits going straight to turtle-saving charities and
            ocean clean-ups.{" "}
          </p>
        </ResponsiveWrapper>

        <div className="scrolling-image-container">
          <img
            src="./config/images/199.png"
            alt="Scrolling Image"
            className="scrolling-image"
          />
        </div>

        <ResponsiveWrapper>
          <p
            style={{
              fontSize: "48px",
              maxWidth: "800px",
              margin: "auto",
              lineHeight: "72px",
            }}
          >
            <br></br>
            This isn’t just a coin; it’s a movement for the homies who care
            about the planet and their wallets.
            <br></br>
            <br></br>
            So, if you’re about that “get-rich-while-doing-good” energy, hop on
            the wave and let’s make saving turtles the next big flex. 🌊✨
          </p>
        </ResponsiveWrapper>
        <div className="scrolling-image-container">
          <img
            src="./config/images/977.png"
            alt="Scrolling Image"
            className="scrolling-image-left"
          />
        </div>

        <ResponsiveWrapper>
          <h2
            style={{
              fontSize: "72px",
              margin: "auto",
              marginTop: "72px",
              maxWidth: "1200px",
            }}
          >
            The Little Turtle Club Donation Plan
          </h2>
        </ResponsiveWrapper>
        <ResponsiveWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "72px",
            maxWidth: "1200px",
          }}
        >
          <div className="column" style={{ width: "33%" }}>
            <h3
              style={{
                padding: "24px",
                fontSize: "36px",
                textAlign: "center",
              }}
            >
              💵 200K Market Cap 💵
            </h3>
            <p
              style={{ padding: "36px", fontSize: "24px", lineHeight: "36px" }}
            >
              The development team will liquidate 25% of their holdings, with
              10% of the proceeds donated to SeaTurtles.org to support their
              vital work in turtle conservation.
            </p>
          </div>
          <div className="column" style={{ width: "33%" }}>
            <h3
              style={{
                padding: "24px",
                fontSize: "36px",
                textAlign: "center",
              }}
            >
              💸 500K Market Cap 💸
            </h3>
            <p
              style={{ padding: "36px", fontSize: "24px", lineHeight: "36px" }}
            >
              Upon reaching this milestone, the team will liquidate an
              additional 25% of their holdings and allocate 10% of the proceeds
              to the World Wildlife Fund (WWF) to further expand our
              environmental impact.
            </p>
          </div>
          <div className="column" style={{ width: "33%" }}>
            <h3
              style={{
                padding: "24px",
                fontSize: "36px",
                textAlign: "center",
              }}
            >
              💰 1M Market Cap 💰
            </h3>
            <p
              style={{ padding: "36px", fontSize: "24px", lineHeight: "36px" }}
            >
              At the 1M market cap milestone, the team will liquidate another
              25% of their holdings, with 10% of the proceeds donated to a
              charity selected by the community, ensuring a collaborative and
              transparent approach to giving back.
            </p>
          </div>
        </ResponsiveWrapper>

        {CONFIG.ROADMAP ? (
          <ResponsiveWrapper>
            <Roadmap alt={"example"} src={"./config/images/roadmap.jpg"} />
            <RoadmapMax alt={"example"} src={"./config/images/turtlebg.jpg"} />
          </ResponsiveWrapper>
        ) : (
          <ResponsiveWrapper>
            <RoadmapMax
              style={{ width: "100%" }}
              alt={"example"}
              src={"./config/images/bg2.png"}
            />
          </ResponsiveWrapper>
        )}
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          {/* <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
